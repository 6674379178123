
// @ts-nocheck
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as VForm from 'vuetify/es5/components/VForm'
import * as VTextField from 'vuetify/es5/components/VInput'
import * as VRadioGroup from 'vuetify/es5/components/VRadioGroup'

import PiePlayer from './pie/PiePlayer'
import PieApiAuthor from './pie/PieApiAuthor'
import PieApiPlayer from './pie/PieApiPlayer.vue'

/*
 * Vue Component
 */
@Component({
  components: {
    ...VTextField,
    ...VForm,
    ...VRadioGroup,
    PiePlayer,
    PieApiAuthor,
    PieApiPlayer,
  },
  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
    ...mapGetters('clouseau', ['playerType']),
  },
  methods: {
    ...mapActions('auth', {
      authInit: 'init',
    }),
    ...mapMutations('clouseau', ['setPlayerType']),
  },
})
export default class Inspector extends Vue {
  private ready: boolean = false

  get piePlayerType(): string {
    return this.playerType
  }
  set piePlayerType(v: string) {
    this.setPlayerType(v)
  }

  get componentType() {
    switch (this.playerType) {
      case 'pie-api-author':
        return 'PieApiAuthor'
      case 'pie-api-player':
        return 'PieApiPlayer'
      default:
        return 'PiePlayer'
    }
  }

  async mounted() {
    await this.authInit()
    this.ready = true
  }
}
