
// @ts-nocheck
import Vue from 'vue'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { StateAuth } from '@/helpers/state'
import { playerEndpoints } from '@/plugins/pie'

@Component({
  computed: {
    ...mapGetters('clouseau', ['itemRemoteId', 'contentType']),
  },
  methods: {
    ...mapMutations('clouseau', ['setItemRemoteId', 'setContentType']),
    ...mapActions('itemConfigs', ['getItemConfig', 'getPassageConfig']),
  },
})
export default class InspectorPiePlayer extends Mixins(Vue, StateAuth) {
  private config: any = null
  private mode: string = 'view'
  private role: string = 'instructor'
  private addCorrectResponse: boolean = true
  private lockChoiceOrder: boolean = false

  get id(): string {
    return this.itemRemoteId
  }
  set id(v: string) {
    this.setItemRemoteId(v)
  }

  get modes(): string[] {
    return ['view', 'gather']
  }

  get type(): string {
    return this.contentType
  }
  set type(v: string) {
    this.setContentType(v)
  }

  onLoaded() {
    console.log('Item Loaded')
  }
  onError(error) {
    console.warn(error)
  }

  async initPlayer() {
    if (this.contentType === 'item') {
      return this.initPlayerForItem()
    }
    return this.initPlayerForPassage()
  }

  async initPlayerForItem() {
    const player: any = this.$refs.player
    this.config = null
    player.config = null

    try {
      const { data } = await this.getItemConfig({ versionedId: this.id, cacheFirst: false })
      this.config = data.contentItem.config
      player.env = {
        ...player.env,
        ...{
          '@pie-element': {
            lockChoiceOrder: this.mode === 'view' ? true : this.lockChoiceOrder,
          },
        },
        mode: this.mode,
        role: this.role,
      }
      player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      player.bundleHost = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      player.bundleEndpoints = playerEndpoints
      player.addCorrectResponse = this.addCorrectResponse
      player.optimize = false
      player.config = this.config
    } catch (error) {
      this.onError(error)
    }
  }

  async initPlayerForPassage() {
    const player: any = this.$refs.player
    this.config = null
    player.config = null

    try {
      const { data } = await this.getPassageConfig({ remoteId: this.id, cacheFirst: false })
      this.config = data.stimulus.config
      player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      player.bundleHost = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      player.bundleEndpoints = playerEndpoints
      player.config = this.config
    } catch (error) {
      this.onError(error)
    }
  }

  submit() {
    this.initPlayer()
  }
}
