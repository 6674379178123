
// @ts-nocheck
import Vue from 'vue'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { StateAuth, StateFeatures } from '@/helpers/state'
import { authorSettings, ibxItemTypes } from '@components/itemAuthor/configs/itemConfigs.js'
import { Id, Bank, ItemConfigs, ItemTypes, ItemConfig, ItemType } from '@/components/ibx/base/Types'
import _ from 'lodash'
import { FLAG } from '@constants'
import { playerEndpoints } from '@/plugins/pie'

enum PlayerEventsType {
  CONTENT_LOADED = 'contentLoaded',
  MODEL_LOAED = 'modelLoaded',
  MODEL_UPDATED = 'modelUpdated',
}

@Component({
  computed: {
    ...mapGetters('clouseau', ['itemRemoteId', 'authorNewItem', 'authorItemType', 'authorMode', 'authorConfig']),
  },
  methods: {
    ...mapMutations('clouseau', ['setItemRemoteId', 'setAuthorItemType', 'setAuthorMode']),
    ...mapActions('itemConfigs', ['getItemConfig']),
  },
})
export default class InspectorPiePlayer extends Mixins(Vue, StateAuth, StateFeatures) {
  private config: any = null
  private ready: boolean = false
  private configs: ItemConfigs

  get id(): string {
    return this.itemRemoteId
  }
  set id(v: string) {
    this.setItemRemoteId(v)
  }

  get mode(): string {
    return this.authorMode
  }

  set mode(v: string): string {
    this.setAuthorMode(v)
  }

  get itemType(): string {
    return this.authorItemType
  }

  set itemType(v: string) {
    this.setAuthorItemType(v)
  }

  get itemTypes(): string[] {
    return ibxItemTypes.map((o) => o.id)
  }

  get supportsRubric(): boolean {
    return ['constructed_response', 'drawing_response'].includes(this.itemType)
  }

  get supportsMultiLevelRubric(): boolean {
    const supported = ['constructed_response']
    return supported.includes(this.itemType) && this.featureFlag(FLAG.MULTI_LEVEL_RUBRICS)
  }

  onModeChange() {
    this.ready = false
  }

  async onLoaded() {
    console.log('Loaded')
  }

  onModelLoaded() {
    console.log('Model Loaded')
  }

  onError(error) {
    console.warn(error)
  }

  async initPlayer() {
    const player: any = this.$refs.player

    try {
      player.token = this.authPieToken
      player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      player.bundleEndpoints = playerEndpoints
      player.lockVersions = false
      this.ready = true
    } catch (error) {
      this.onError(error)
    }
  }

  async loadNewItem() {
    const player: any = this.$refs.player
    const settings = _.cloneDeep(authorSettings[this.itemType].configSettings)
    const config = _.cloneDeep(authorSettings[this.itemType].contentConfig)

    //set partial scoring default
    config.models = config.models.map((o: any) => {
      o.partialScoring = true
      return o
    })

    player.configSettings = settings

    if (this.supportsRubric) {
      try {
        const rubricModel = await player.addRubricToConfig(config)
        player.contentConfig = rubricModel
      } catch (error) {
        console.warn(error)
      }
    } else {
      player.contentConfig = config
    }
  }

  async loadExistingItem() {
    const player = this.$refs.player

    // get config and confif settings
    player.configSettings = _.cloneDeep(authorSettings[this.itemType].configSettings)
    const { data } = await this.getItemConfig({ versionedId: this.id, cacheFirst: false })
    const config = _.cloneDeep(data.contentItem.config)

    this.setLocalItemTypeFromModel(config)

    // set config/check for rubric
    if (this.supportsRubric) {
      try {
        const rubricModel = await player.addRubricToConfig(config)
        player.contentConfig = rubricModel
      } catch (error) {
        console.warn(error)
      }
    } else {
      player.contentConfig = config
    }
  }

  getConfigSettings(settings: any = {}): any {
    return _.cloneDeep(settings)
  }

  setLocalItemTypeFromModel(config = {}) {
    const elements = Object.values(config.elements || {})
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].includes('drawing-response')) {
        this.itemType = 'drawing_reponse'
        break
      }

      if (elements[i].includes('extended-text-entry')) {
        this.itemType = 'constructed_response'
        break
      }
    }
  }

  async getRubricSupport(contentConfig = {}) {
    const player = this.$refs.player

    // rubric support type
    if (this.supportsRubric) {
      if (this.supportsMultiLevelRubric) {
        if (contentConfig.models) {
          for (const model of contentConfig.models) {
            if (!model.rubrics) {
              model.rubricEnabled = true
            }
          }
        }
      } else {
        // legacy
        contentConfig = await player.addRubricToConfig(contentConfig)
      }
    }

    return contentConfig
  }

  async loadItemFromModel() {
    const player = this.$refs.player
    player.lockVersions = false

    let contentConfig = this.authorConfig
    this.setLocalItemTypeFromModel(contentConfig)

    // settings and config/model
    player.configSettings = this.getConfigSettings(this.configs.getSettings(this.itemType))
    contentConfig = await this.getRubricSupport(contentConfig)
    player.contentConfig = contentConfig
  }

  init() {
    this.configs = ItemConfigs.getInstance()
  }

  submit() {
    this.initPlayer()
    switch (this.mode) {
      case 'new':
        this.loadNewItem()
        break
      case 'existing':
        this.loadExistingItem()
        break
      case 'model':
        this.loadItemFromModel()
        break
    }
  }

  mounted() {
    this.init()
  }
}
