
// @ts-nocheck
import Vue from 'vue'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { StateAuth } from '@/helpers/state'

@Component({
  computed: {
    ...mapGetters('clouseau', ['itemRemoteId', 'sessionId', 'sessionOrId', 'assignmentId']),
  },
  methods: {
    ...mapMutations('clouseau', ['setItemRemoteId', 'setSessionId', 'setSessionOrId', 'setAssignmentId']),
    ...mapActions('itemConfigs', ['getItemConfig']),
  },
})
export default class InspectorPiePlayer extends Mixins(Vue, StateAuth) {
  private ready: boolean = false
  private mode: string = 'view'
  private role: string = 'instructor'
  private lockChoiceOrder: boolean = false

  get modes(): string[] {
    return ['view', 'gather']
  }

  get id(): string {
    return this.itemRemoteId
  }
  set id(v: string) {
    this.setItemRemoteId(v)
  }

  get session(): string {
    return this.sessionId
  }
  set session(v: string) {
    this.setSessionId(v)
  }

  get sessOrId(): string {
    return this.sessionOrId
  }
  set sessOrId(v: string) {
    this.setSessionOrId(v)
  }

  get assignment(): string {
    return this.assignmentId
  }
  set assignment(v: string) {
    this.setAssignmentId(v)
  }

  get playerSessionId() {
    return this.sessOrId === 'sessionId' ? this.sessionId : null
  }

  get playerItemId() {
    return this.sessOrId === 'itemId' ? this.id : null
  }

  onSessionCreated(event) {
    console.log('session-created', event)
  }
  onSessionChanged(event) {
    console.log('session-changed', event)
  }
  onSessionSaved(event) {
    console.log('sessionSaved', event)
  }
  onSaveSessionError(event) {
    console.log('saveSessionError', event)
  }
  onLoadComplete() {
    console.log('load-complete')
  }
  onPlayerError(e) {
    console.log('player-error', e)
  }
  onContentLoaded() {
    console.log('content-loaded')
  }

  async initPlayer() {
    try {
      this.player = this.$refs.player
      this.player.env = {
        ...this.player.env,
        mode: this.mode,
        role: this.role,
        ...{
          '@pie-element': {
            lockChoiceOrder: this.lockChoiceOrder,
          },
        },
      }

      this.player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      this.player.token = this.authPieToken
      this.player.renderStimulus = false
      this.player.itemId = this.playerItemId
      this.player.sessionId = this.playerSessionId
      this.player.preview = false
      this.player.optimize = false
      this.player.assignmentId = this.assignment
      this.ready = true
    } catch (error) {
      this.onPlayerError
    }
  }

  submit() {
    this.ready = false
    this.initPlayer()
  }
}
